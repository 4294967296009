import React, { useEffect, useState } from "react";

import recordIcon from "../../img/mic_recording_icon.svg";
import voiceLogo from "../../img/mic_icon.svg";
import msgSend from "../../img/send_icon.svg";
import attach_icon from "../../img/chat/attach_icon.svg";
import upload_done from "../../img/chat/upload_done_icon.svg";
import { useHistory } from 'react-router-dom';
import "../ChatInput/ChatInput.css";

const ChatInput = ({
  textareaRef,
  input,
  setInput,
  handleInputChange,
  getLetterCount,
  handleKeyDown,
  handleKeyUp,
  isSubmitting,
  disableMicButton,
  getPlaceholderText,
  suggestions,
  showDropdown,
  handleSuggestionClick,
  disableSendButton,
  handleMicButtonClick,
  isVoiceRecognitionActive,
  dropdownRef,
  getStyles,
  isRecording,
  handleImageChange,
  inputFileRef,
  handleImageUpload,
  selectedImage,
  imagePreview,
  selectedOption,
  setReturnBar,
  boxSuggession,
  setCenterHome
}) => {
  const getSubmit = () => {
    const boxShadow = input === "" ? "none" : "";
    const display = input === "" ? "none" : "block";

    return { boxShadow, display };
  };
  const getVoice = () => {
    const display = input === "" ? "Block" : "none";
    return { display };
  };
  const getText = () => {
    const background = input === "" ? "" : "#fff";
    return { background };
  };


  const suggessionBorderColor = {
    borderColor: boxSuggession,
  };


  // window.addEventListener('popstate', (event) => {
  //   alert("You message");
  // });
  // console.log(getLetterCount())

  const getColor = () => {
    return getLetterCount() <= 2000 ? 'green' : 'red';
  };

  // // State to manage the typing text input value
  // const [typingText, setTypingText] = useState('');

  // // useEffect to retrieve stored text from localStorage on component mount
  // useEffect(() => {
  //   const storedText = localStorage.getItem('typedText');
  //   if (storedText) {
  //     setTypingText(storedText);
  //     setInput(storedText)
  //     console.log("retrived " + storedText)
  //   }
  // }, []);

  // // useEffect to update localStorage whenever the typing text changes
  // useEffect(() => {
  //   localStorage.setItem('typedText', input);
  // }, [input]);

  // // Function to handle typing text change
  // const handleTypingTextChange = (event) => {
  //   setTypingText(event.target.value);
  // };



  // const handleClearTypingText = () => {
  //   setTypingText('');
  //   localStorage.removeItem('typedText');
  // };


  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  const handleMouseEnterSend = () => {
    const timeout = setTimeout(() => {
      setShowTooltip(true);
    }, 2000); // 2 seconds
    setHoverTimeout(timeout);
  };

  const handleMouseLeaveSend = () => {
    clearTimeout(hoverTimeout);
    setShowTooltip(false);
  };


  const [showTooltipMic, setShowTooltipMic] = useState(false);
  // const [hoverTimeout, setHoverTimeout] = useState(null);

  const handleMouseEnterMic = () => {
    const timeout = setTimeout(() => {
      setShowTooltipMic(true);
    }, 2000); // 2 seconds
    setHoverTimeout(timeout);
  };

  const handleMouseLeaveMic = () => {
    clearTimeout(hoverTimeout);
    setShowTooltipMic(false);
  };


  function formatCharacterCount(count) {
    return count.toString().padStart(4, '0');
  }
  const textCount = input.replace(/\s+/g, '').length;
  const formattedCount = formatCharacterCount(textCount);


  return (
    <div className="input-container start-input-container">

      <div className="input-container-wrapper" style={{ ...getText(), ...suggessionBorderColor }}>
        {/* {selectedImage && (
        <div>
          <p>Selected Image: {selectedImage}</p>
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Selected Preview"
              style={{ maxWidth: '100%', maxHeight: '200px' }}
            />
          )}
        </div>
      )} */}
        <div className="input-container-inner">
          <textarea
            id="typedText"
            ref={textareaRef}
            className="kps-input-box"
            type="text"
            placeholder={getPlaceholderText()}
            value={input}
            onChange={(e) => {
              handleInputChange(e);

            }}

            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            // disabled={isSubmitting || disableMicButton}
            required
          />


          {input && suggestions.length > 0 && showDropdown && (
            <ul className="autocomplete-dropdown" ref={dropdownRef}>
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="autocomplete-item"
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
          {(selectedOption === "option3" || selectedOption === "option4") && <div className="upload-img-wrapper">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              ref={inputFileRef}
            />
            <button type="button" onClick={handleImageUpload} className="comman-button-text-input image-upload">
              {!selectedImage && (<img src={attach_icon} alt="Upload" className="comman-button-text-input-img " />)}
              {selectedImage && (<img src={upload_done} alt="Upload" className="comman-button-text-input-img " />)}


            </button>

          </div>}
          <div className="submit-wrapper">
            <div className="submit-voice">
              <span style={getSubmit()}>
                <button
                  type="submit"
                  disabled={disableSendButton}
                  className="submit-button comman-button-text-input sendtool"
                  onClick={() => { setCenterHome(false); }}
                  onMouseEnter={handleMouseEnterSend}
                  onMouseLeave={handleMouseLeaveSend}
                >
                  <img
                    className="comman-button-text-input-img"
                    src={msgSend}
                    alt="Chat"
                    disabled={disableSendButton || isSubmitting}
                  />
                  {showTooltip && <div style={{ color: '#000', }}>
                    <div class="">
                      <span class="sendtooltext" style={{ marginLeft: '-76px' }}>Submit</span>
                    </div>
                  </div>}

                </button>
              </span>

              <span style={getVoice()}>
                <button
                  className="submit-button ripple-btn comman-button-text-input sendtool"
                  type="button"
                  onClick={handleMicButtonClick}
                  disabled={disableMicButton}
                  onMouseEnter={handleMouseEnterMic}
                  onMouseLeave={handleMouseLeaveMic}
                >
                  {isRecording ? (
                    <img
                      className="comman-button-text-input-img chat-logo-record"
                      src={recordIcon}
                      alt="Record"
                    />
                  ) : (
                    <img
                      className="comman-button-text-input-img "
                      src={voiceLogo}
                      alt="Microphone"
                    />

                    // <div>
                    // <animated.div
                    // className="comman-button-text-input-img chat-logo-record"
                    //   style={{
                    //     width: '8px',
                    //     height: '8px',
                    //     backgroundColor: 'red',
                    //     transform: animationProps.scale.interpolate((s) => `scale(${s})`),
                    //   }}
                    // />
                    // </div>
                  )}
                  {showTooltipMic && <div style={{ color: '#000', }}>
                    <div class="">
                      <span class="sendtooltext">Use microphone</span>
                    </div>
                  </div>}
                </button>
              </span>

            </div>
          </div>
        </div>
        <div className="count-wrapper">
          <div className="count-container"
            style={{
              color: textCount === 0 ? '#aaaeaa' : (textCount < 2000 ? 'green' : 'red')
            }}

          >
            {formattedCount}
          </div>


        </div>
      
      </div>

    </div>
  );
};

export default ChatInput;
