import React, { createContext, useContext, useState } from 'react';
import generalChat from "../img/chat/general_chat.png";
import emojiStrip from "emoji-strip";


const ShareWindowContext = createContext();

export const ShareWindowProvider = ({ children }) => {
  const [showShareWindow, setShowShareWindow] = useState(false);
  const [minMaxHeight, setMinMaxHeight] = useState();
  const [chatSession, setChatSession] = useState("");
  const [generatedLink, setGeneratedLink] = useState("");
  const [textCount, setTextCount] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [backgroundText, setBackgroundText] = useState("24/7 Health Chat");
  const [backgroundImg, setBackgroundImg] = useState(generalChat);
  const [backgroundreturnColor, setBackgroundreturnColor] = useState("#C1D6F0");
  const [isVoiceSynthesisInProgress, setIsVoiceSynthesisInProgress] =
  useState(false);
  const [currentlyPlayingIndex, setCurrentlyPlayingIndex] = useState(null);
  const [isAutoPlayInProgress, setIsAutoPlayInProgress] = useState(false);
  const [showStopButton, setShowStopButton] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disableSendButton, setDisableSendButton] = useState(false);
  const [disableMicButton, setDisableMicButton] = useState(false);
  const [isVoiceSynthesisActive, setIsVoiceSynthesisActive] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] = useState(null);
  const [audio, setAudio] = useState(null);
  const [audioData, setAudioData] = useState({});
  const [textResponse, setTextResponse] = useState("");
  const [userHistory, setUserHistory] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [deskNav, setDeskNav] = useState(false);



  const handleStopButtonClick = () => {
    if (isAutoPlayInProgress) {
      // If auto-play is in progress, stop the currently playing auto-play audio
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
      setIsAutoPlayInProgress(false);

      // Update the button to show the play icon
      setShowStopButton(false);
    } else if (audio) {
      // If not in auto-play, stop the currently playing user-initiated audio
      audio.pause();
      audio.currentTime = 0;

      // Update the button to show the play icon
      setShowStopButton(false);
    }

    // Reset other relevant states
    setIsSubmitting(false);
    setDisableSendButton(false);
    setDisableMicButton(false);
    setIsVoiceSynthesisActive(false);
    setIsAudioPlaying(false);
    setCurrentlyPlayingIndex(null);
    setCurrentAudio(null);
    setCurrentlyPlayingAudio(null);
  };

  const playAudio = (text, index) => {
    const textWithoutEmojis = emojiStrip(text);

    if (audioData[textWithoutEmojis]) {
      console.log(textWithoutEmojis)


      if (currentlyPlayingAudio) {
        currentlyPlayingAudio.pause();
        currentlyPlayingAudio.currentTime = 0;

        // Reset the currently playing index
        setCurrentlyPlayingIndex(null);

      }

      // Start playing the newly clicked audio
      const audioElement = new Audio(audioData[textWithoutEmojis]);
      audioElement.preload = "auto";

      audioElement.onplay = () => {
        setCurrentlyPlayingIndex(index);
        setCurrentlyPlayingAudio(audioElement);
      };
      audioElement.onended = () => {
        setCurrentlyPlayingIndex(null);
        setCurrentlyPlayingAudio(null);
      };

      audioElement.play();
      setAudio(audioElement);
    }
  };


  
  return (
    <ShareWindowContext.Provider value={{deskNav, setDeskNav, showDropdown, setShowDropdown, userHistory, setUserHistory, textResponse, setTextResponse, playAudio, audioData, setAudioData, handleStopButtonClick, audio, setAudio, currentlyPlayingAudio, setCurrentlyPlayingAudio, isAudioPlaying, setIsAudioPlaying, isVoiceSynthesisActive, setIsVoiceSynthesisActive, disableMicButton, setDisableMicButton, disableSendButton, setDisableSendButton, isSubmitting, setIsSubmitting, currentAudio, setCurrentAudio, showStopButton, setShowStopButton, isAutoPlayInProgress, setIsAutoPlayInProgress, currentlyPlayingIndex, setCurrentlyPlayingIndex, isVoiceSynthesisInProgress, setIsVoiceSynthesisInProgress, backgroundreturnColor, setBackgroundreturnColor, backgroundImg, setBackgroundImg, backgroundText, setBackgroundText,isButtonDisabled, setIsButtonDisabled, textCount, setTextCount, showShareWindow, setShowShareWindow, chatSession, setChatSession, generatedLink, setGeneratedLink, minMaxHeight, setMinMaxHeight }}>
      {children}
    </ShareWindowContext.Provider>
  );
};

export const useShareWindow = () => {
  const context = useContext(ShareWindowContext);
  if (!context) {
    throw new Error('useShareWindow must be used within a ShareWindowProvider');
  }
  return context;
};
