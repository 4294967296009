import React, { useState, useEffect, useRef } from "react";
// import { useSpring, animated } from 'react-spring';
import ReactDOMServer from "react-dom/server";

import axios from "axios";
import voiceLogo from "../../img/mic_icon.svg";
import msgSend from "../../img/send_icon.svg";
import image2 from "../../img/health-care.png";
import image3 from "../../img/doctor2.png";
import image4 from "../../img/Jesus_Background.jpg";
import image5 from "../../img/med.jpg";
import video1 from "../../img/knightingale.mp4";
import micSvg from "../../img/speaker.png";
import play from "../../img/Knightingale_said.png";
import recordIcon from "../../img/mic_recording_icon.svg";
import notificationSound from "../../sounds/notification sound.mp3";
import stop from "../../img/Knightingale_says.png";
import loadingModel from "../../img/knightingale_typing.png";
import emojiStrip from "emoji-strip";
import { useSpring, animated } from "react-spring";
import symptomChecker from "../../img/chat/symptom-checker.png";
import reportAnalyzer from "../../img/chat/report-analyzer.png";
import calorieCounter from "../../img/chat/calorie-counter.png";
import wellnessCouch from "../../img/chat/wellness-coach.png";
import sleepHelper from "../../img/chat/sleep-helper.png";
import workoutPlanner from "../../img/chat/workout-planner.png";
import womenHealth from "../../img/chat/women-health.png";
import share from "../../img/chat/share.png";
import careReminder from "../../img/chat/care-reminder.png";
import ShareWindow from "../Sharewindow/ShareWindow";
import mixpanel from 'mixpanel-browser';
import { useShareWindow } from "../../context/ShareWindowContext.js";


// import symptomCheckerMp4 from "../../videos/chat/symptom-checker.mp4";
// import reportAnalyzerMp4 from "../../videos/chat/report-analyzer.mp4";
// import calorieCounterMp4 from "../../videos/chat/calorie-counter.mp4";
// import wellnessCouchMp4 from "../../videos/chat/wellness-coach.mp4";
// import sleepHelperMp4 from "../../videos/chat/sleep-helper.mp4";
// import workoutPlannerMp4 from "../../videos/chat/workout-planner.mp4";
// import investorRelationsMp4 from "../../videos/chat/women-health.mp4";
// import careReminderMp4 from "../../videos/chat/care-reminder.mp4";
import symptomCheckerMp4 from "../../videos/chat/knightingale_character.mp4";
import reportAnalyzerMp4 from "../../videos/chat/knightingale_character.mp4";
import calorieCounterMp4 from "../../videos/chat/knightingale_character.mp4";
import wellnessCouchMp4 from "../../videos/chat/knightingale_character.mp4";
import sleepHelperMp4 from "../../videos/chat/knightingale_character.mp4";
import workoutPlannerMp4 from "../../videos/chat/knightingale_character.mp4";
import investorRelationsMp4 from "../../videos/chat/knightingale_character.mp4";
import careReminderMp4 from "../../videos/chat/knightingale_character.mp4";
import mornning from "../../img/chat/morning_icon.png";

import myprofile from "../../img/chat/my-profile.png";
import myprogress from "../../img/chat/my-pogress.png";
import myplans from "../../img/chat/my-plans.png";

import clorineCnt from "../../img/chat/clorine-cnt.png";

import mainBgVideo from "../../videos/knightingale_character_loop.mp4";

import "./Form.css";
import Chat from "../Chat/Chat";
import ChatInput from "../ChatInput/ChatInput";
import { useParams } from "react-router-dom";

/*for the localhost:
const AI_API_URL = 'https://backend.knightingale.ai/api/ai';
const VOICE_RECOGNITION_API_URL = 'https://backend.knightingale.ai/api/voice-recognition';

for the server:
const AI_API_URL = 'https://backend.knightingale.ai/api/ai';
const VOICE_RECOGNITION_API_URL = 'https://backend.knightingale.ai/api/voice-recognition';
*/
const AI_API_URL = "https://backend.knightingale.ai/api/ai";
const VOICE_RECOGNITION_API_URL =
  "https://backend.knightingale.ai/api/voice-recognition";
const AUDIO_SYNTHESIS_API_URL =
  "https://backend.knightingale.ai/api/convert-text-to-speech";
const AUTOCOMPLETE_API_URL = "https://backend.knightingale.ai/api/autocomplete";
const VISION = "https://backend.knightingale.ai/upload-image";
const CONVERSATION_STARTER = "https://backend.knightingale.ai/conversation-starter";
const CONV_RESPONSE = "https://backend.knightingale.ai/chat-starter-response";

const axiosInstance = axios.create();
const axiosVoiceRecognitionInstance = axios.create();

mixpanel.init('f3bc0a936d16d0fd8b1da0c66e4ae0b0', { debug: true, track_pageview: true, persistence: 'localStorage' });


function Form({
  setBackgroundColor,
  setBackgroundText,
  setBackgroundImg,
  setBackgroundMp,
  setBackgroundreturnColor,
  setReturnBar,
  backgroundMp,
  removeVideo,
  setCenterHome,
}) {
  const [input, setInput] = useState("");
  const [selectedOption, setSelectedOption] = useState("option1");
  // const [userHistory, setUserHistory] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState(image5);
  const [backgroundVideo, setBackgroundVideo] = useState(video1);
  
  const [showOptions, setShowOptions] = useState(false);
  const [chatStart, setChatStart] = useState(false);
  const [isVoiceRecognitionActive, setIsVoiceRecognitionActive] =
    useState(false);
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const [disableMicButton, setDisableMicButton] = useState(false);
  // const [disableSendButton, setDisableSendButton] = useState(false);
  // const [isVoiceSynthesisActive, setIsVoiceSynthesisActive] = useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  // const [audio, setAudio] = useState(null);
  // const [showStopButton, setShowStopButton] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  // const [showDropdown, setShowDropdown] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  // const [textResponse, setTextResponse] = useState("");
  // const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isFirstResponse, setIsFirstResponse] = useState(true);
  // const [audioData, setAudioData] = useState({});
  // const [currentlyPlayingIndex, setCurrentlyPlayingIndex] = useState(null);
  // const [currentAudio, setCurrentAudio] = useState(null);
  // const [isAutoPlayInProgress, setIsAutoPlayInProgress] = useState(false);
  // const [currentlyPlayingAudio, setCurrentlyPlayingAudio] = useState(null);
  // const [isVoiceSynthesisInProgress, setIsVoiceSynthesisInProgress] =
  //   useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [responseTexts, setResponseTexts] = useState(["", "", "", ""]);
  const [isSelectSuggest, setSelectSuggest] = useState(true);
  const [imgSuggession, setImgSuggession] = useState();
  const [suggessionTitle, setSuggessionTitle] = useState();
  const [boxSuggession, setBoxSuggession] = useState("#EAEDF1");
  const [conversationHistory, setConversationHistory] = useState([]);
  const { session: urlSession } = useParams();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { currentlyPlayingIndex, setCurrentlyPlayingIndex, setMinMaxHeight, minMaxHeight,deskNav, setDeskNav,
    showShareWindow, setShowShareWindow, chatSession, setChatSession,
    generatedLink, setGeneratedLink, isVoiceSynthesisInProgress, setIsVoiceSynthesisInProgress,
    isAutoPlayInProgress, setIsAutoPlayInProgress, showStopButton, setShowStopButton, isSubmitting, setIsSubmitting,
    currentAudio, setCurrentAudio, disableSendButton, setDisableSendButton, 
    currentlyPlayingAudio, setCurrentlyPlayingAudio, disableMicButton, setDisableMicButton, isVoiceSynthesisActive,
    audio, setAudio, setIsVoiceSynthesisActive, isAudioPlaying, setIsAudioPlaying, handleStopButtonClick,
    audioData, setAudioData, playAudio, textResponse, setTextResponse, userHistory, setUserHistory, showDropdown, setShowDropdown
  } = useShareWindow();
  // const [showShareWindow, setShowShareWindow] = useState(false);
  // const [chatSession, setChatSession] = useState("");

  const textCount = input.replace(/\s+/g, '').length;
  console.log(textCount)

  const textareaRef = useRef(null);
  const outputContainerRef = useRef(null);
  const outputinnerContainerRef = useRef(null);

  const dropdownRef = useRef(null);
  const otContainerRef = useRef(null);

  const regexBold = /\*\*(.*?)\*\*/g;




  console.log(minMaxHeight)

  useEffect(() => {
    if (urlSession) {
      // If a session ID is present in the URL, use it
      setChatSession(urlSession);

      // Fetch conversation history for the current chat session
      axios
        .get(`https://backend.knightingale.ai/api/conversation-history/${urlSession}`)
        .then((response) => {
          // Update conversation history state
          setConversationHistory(response.data);
        })
        .catch((error) => {
          console.error("Error fetching conversation history:", error);
        });
    } else {
      // If no session ID in the URL, fetch a new session ID when the component mounts
      axios
        .get("https://backend.knightingale.ai/api/generate-session")
        .then((response) => {
          const newChatSession = response.data.chatSession;
          setChatSession(newChatSession);
          // Store the new session ID in localStorage
          localStorage.setItem("chatSession", newChatSession);
        })
        .catch((error) => {
          console.error("Error generating session:", error);
        });
    }
  }, [urlSession]); // Run the effect when the URL session changes

  // Construct the unique link for the current session
  const uniqueLink = `https://guardian.knightingale.ai/share/${chatSession}`;

  // Display the unique link and conversation history to the user
  // console.log("Unique Link:", uniqueLink);
  // console.log("Conversation History:", conversationHistory);

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  useEffect(() => {
    if (!(selectedOption === "option1")) {
      removeClasses();
      setChatStart(true);
    }
  }, [selectedOption, backgroundMp]);

  useEffect(() => {
    changeSelectedOption("option1");
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [userHistory, showLoadingIndicator]);

  const scrollToBottom = () => {
    const parentElement = document.querySelector(".q-and-a-container");
    const outputContainer = outputContainerRef.current;
    const outputInner = outputinnerContainerRef.current;

    if (outputContainer) {
      const lastChild = outputContainer.lastChild;
      const childElements = parentElement.querySelectorAll(".main-q-a-box");

      if (lastChild && childElements.length > 0) {
        const containerHeight = outputContainer.clientHeight;
        const lastChildHeight = lastChild.clientHeight;
        const scrollHeight = outputContainer.scrollHeight;
        const innerHeight = outputInner.clientHeight;
        const lastChilds = childElements[childElements.length - 1];
        const lastChildHei = lastChilds.getBoundingClientRect().height;
        if (lastChildHei > containerHeight - 45) {
          outputContainer.scrollTop =
            scrollHeight -
            (containerHeight + 80) -
            (lastChildHei - (containerHeight - 50));
        } else {
          if (scrollHeight - containerHeight <= lastChildHeight) {
            outputContainer.scrollTop = scrollHeight - (containerHeight - 50);
          } else {
            lastChild.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }
      }
    }
  };

  // const handleScroll = (event) => {
  //   const container = otContainerRef.current;
  //   const delta = event.deltaY; // Get the scroll delta

  //   const newScrollTop = container.scrollTop + delta;

  //   container.style.transition = 'transform 0.5s ease-in-out';
  //   container.style.transform = `translateY(${newScrollTop}px)`;

  //   event.preventDefault();

  // }

  const handleSubmit = async (e) => {
    temporarySendMessege(input);

    setInput("");

    e.preventDefault();

    if (!input.trim()) {
      return;
    }

    try {
      setIsSubmitting(true);
      setDisableSendButton(true);
      setDisableMicButton(true);
      setIsVoiceSynthesisActive(true);
      setShowLoadingIndicator(true);
      removeClasses();
      setChatStart(true);
      setSelectSuggest(false);
      mixpanel.track('Query Sumbitted', { 'Button Type': 'querysumbitted' })

      // Prepare the conversation history with system and user messages
      const updatedConversation = [
        ...userHistory.flatMap((entry) => [
          { role: "user", content: entry.input },
          { role: "assistant", content: entry.response },
        ]),
        { role: "user", content: input },
      ];
      const res = await axiosInstance.post(AI_API_URL, {
        conversations: updatedConversation,
        selectedOption,
        chatSession,
      });

      const textResponse = res.data.completion;

      removeElementById();

      playNotificationSound();
      const textResponseWithoutEmojis = emojiStrip(textResponse);

      setTextResponse(textResponseWithoutEmojis);
      const styledHtmlContent = res.data.completion.replace(
        regex,
        '<span class="bold-text">$1</span>'
      );
      const newEntry = {
        input,
        response: styledHtmlContent,
      };
      setUserHistory([...userHistory, newEntry]);

      triggerVoiceSynthesis(textResponseWithoutEmojis);

      setIsSubmitting(false);
      setDisableSendButton(false);
      setDisableMicButton(false);
      setIsVoiceSynthesisActive(false);
      setShowLoadingIndicator(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setDisableSendButton(false);
      setDisableMicButton(false);
      setIsVoiceSynthesisActive(false);
      setShowLoadingIndicator(false);
    }
  };

  const removeElementById = () => {
    const elementToRemove = document.getElementById("temp-q-msg");

    if (elementToRemove) {
      elementToRemove.remove();
    } else {
      console.log("Element not found.");
    }
  };

  const temporarySendMessege = (transcription, input) => {
    const parentElement = document.querySelector(".q-and-a-container");
    const childElements = parentElement.querySelectorAll(".main-q-a-box");
    const lastChild = childElements[childElements.length - 1];
    const index = childElements.length;

    const newElement = (
      <div id="temp-q-msg" className="main-q-a-box" key={index}>
        <div className="client-msg-bubble">
          <h2 className="client-questions">
            {transcription}
            {input}
          </h2>
        </div>
      </div>
    );

    const htmlString = ReactDOMServer.renderToStaticMarkup(newElement);

    if (index === 0) {
      parentElement.insertAdjacentHTML("beforeend", htmlString);
    } else if (index > 0) {
      lastChild.insertAdjacentHTML("afterend", htmlString);
    }
  };

  const triggerVoiceSynthesis = async (textResponse, isAutoPlay) => {
    try {
      setIsVoiceSynthesisInProgress(true);

      const audioRes = await axiosInstance.post(AUDIO_SYNTHESIS_API_URL, {
        text: textResponse,
      });

      // Save the audio URL for later playback along with the corresponding text
      const audioUrl = audioRes.data.audioUrl;
      setAudioData((prevAudioData) => ({
        ...prevAudioData,
        [textResponse]: audioUrl,
      }));

      setIsVoiceSynthesisInProgress(false);
    } catch (error) {
      console.error(error);
      setIsVoiceSynthesisInProgress(false);
    }
  };

  // const playAudio = (text, index) => {
  //   const textWithoutEmojis = emojiStrip(text);

  //   if (audioData[textWithoutEmojis]) {
  //     if (currentlyPlayingAudio) {
  //       currentlyPlayingAudio.pause();
  //       currentlyPlayingAudio.currentTime = 0;

  //       // Reset the currently playing index
  //       setCurrentlyPlayingIndex(null);
  //     }

  //     // Start playing the newly clicked audio
  //     const audioElement = new Audio(audioData[textWithoutEmojis]);
  //     audioElement.preload = "auto";

  //     audioElement.onplay = () => {
  //       setCurrentlyPlayingIndex(index);
  //       setCurrentlyPlayingAudio(audioElement);
  //     };
  //     audioElement.onended = () => {
  //       setCurrentlyPlayingIndex(null);
  //       setCurrentlyPlayingAudio(null);
  //     };

  //     audioElement.play();
  //     setAudio(audioElement);
  //   }
  // };

  const handleMicButtonClick = async () => {
    if (!isRecording && !isSubmitting && !isAutoPlayInProgress) {
      setIsRecording(true);
      mixpanel.track('Voice Recognition Used', {'Button Type': 'voicerecognition'})
      try {
        let stream;
        
        // Check if it's iOS
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (isIOS) {
          stream = await navigator.mediaDevices.getUserMedia({
            audio: {
              channelCount: 1,
              echoCancellation: true,
              sampleRate: 44100,
            },
          });
        } else {
          stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
        }
  
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();
        source.connect(analyser);
        analyser.fftSize = 2048;
        const dataArray = new Uint8Array(analyser.fftSize);
  
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
  
        const chunks = [];
        let silenceDetected = true;
        const silenceThreshold = 0.01;
        const silenceDuration = 1000;
  
        const checkForSilence = () => {
          analyser.getByteTimeDomainData(dataArray);
          const isSilent = dataArray.every(value => Math.abs(value - 128) < (128 * silenceThreshold));
          if (!isSilent) {
            silenceDetected = false;
          }
          if (silenceDetected) {
            setTimeout(checkForSilence, silenceDuration);
          }
        };
        
        checkForSilence();
  
        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data);
          }
        };
  
        recorder.onstop = () => {
          if (!silenceDetected) {
            const audioBlob = new Blob(chunks, { type: "audio/wav" });
            sendAudioToBackend(audioBlob);
          }
          setIsRecording(false);
          setMediaRecorder(null);
        };
  
        recorder.start();
        silenceDetected = true;
      } catch (error) {
        console.error("Error accessing microphone:", error);
        setIsRecording(false);
      }
    } else if (mediaRecorder) {
      mediaRecorder.stop();
      setMediaRecorder(null);
      setIsRecording(false);
    }
  };
  
  const sendAudioToBackend = async (audioBlob) => {
    try {
      const formData = new FormData();
      formData.append("audioFile", audioBlob);
  
      const res = await axiosVoiceRecognitionInstance.post(
        VOICE_RECOGNITION_API_URL,
        formData
      );
      const { transcription } = res.data;
  
      temporarySendMessege(transcription);
  
      setShowLoadingIndicator(true);
  
      if (transcription.trim().length > 0) {
        removeClasses();
        const updatedConversation = [
          ...userHistory.flatMap((entry) => [
            { role: "user", content: entry.input },
            { role: "assistant", content: entry.response },
          ]),
          { role: "user", content: transcription },
        ];
  
        const openaiRes = await axiosInstance.post(AI_API_URL, {
          conversations: updatedConversation,
          selectedOption,
          chatSession,
        });
        removeElementById();
  
        playNotificationSound();
        const textResponseWithoutEmojis = emojiStrip(openaiRes.data.completion);
        setTextResponse(textResponseWithoutEmojis);
  
        const styledHtmlContent = openaiRes.data.completion.replace(
          regex,
          '<span class="bold-text">$1</span>'
        );
        const newEntry = {
          input: transcription,
          response: styledHtmlContent,
        };
        setUserHistory([...userHistory, newEntry]);
        setInput("");
  
        setDisableSendButton(false);
        setDisableMicButton(false);
  
        triggerVoiceSynthesis(textResponseWithoutEmojis);
  
        setShowLoadingIndicator(false);
      } else {
        console.log("Empty transcription. Not sending to OpenAI.");
        setShowLoadingIndicator(false);
      }
    } catch (error) {
      console.error(error);
      setShowLoadingIndicator(false);
      setDisableSendButton(false);
      setDisableMicButton(false);
    }
  };
  

  const handleOptionChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option);
    setInput("");
    updateBackgroundImage(option);
  };

  //autocompletion fetching
  /*const fetchAutocompleteSuggestions = async (query) => {
    try {
      const response = await axios.get(
        `${AUTOCOMPLETE_API_URL}?query=${query}`
      );
      const suggestions = response.data.suggestions;
      setSuggestions(suggestions);
    } catch (error) {
      console.error("Error fetching autocomplete suggestions:", error);
    }
  };*/

  const handleInputChange = (e) => {
    const newInput = e.target.value;
    setInput(newInput);
    //fetchAutocompleteSuggestions(newInput);

    // Update showDropdown state to control visibility
    setShowDropdown(newInput !== "");

    adjustTextareaHeight();
    // Opacity change there is text in the textarea
  };

  const getLetterCount = () => {
    return input.length;
  };


  const getStyles = () => {
    const opacity = input === "" ? 0.6 : 1;
    const boxShadow = input === "" ? "none" : "";

    return { opacity, boxShadow };
  };

  //button submit active after add
  const getSubmit = () => {
    const boxShadow = input === "" ? "none" : "";
    const display = input === "" ? "none" : "block";

    return { boxShadow, display };
  };
  const getVoice = () => {
    const display = input === "" ? "Block" : "none";
    return { display };
  };
  const getText = () => {
    const background = input === "" ? "" : "#fff";
    return { background };
  };

  //suggestions selector
  const handleSuggestionClick = (selectedSuggestion) => {
    setInput(selectedSuggestion);
    setSuggestions([]); // Clear the suggestions list
  };



  const [disableFunction, setDisableFunction] = useState(false);

  const handleKeyDown = (e) => {
    if (textCount > 0) {

      if (!disableFunction && e.key === "Enter") {
        handleSubmit(e);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setDisableFunction(window.innerWidth < 767);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter") {
  //     handleSubmit(e);
  //   }
  // };

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);

    audio.play();
    setSelectedImage(null);
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "40px";
    const newHeight = Math.min(textarea.scrollHeight, 130);
    textarea.style.height = `${newHeight}px`;
    console.log(newHeight)
    setMinMaxHeight(newHeight - 44)
  };

  const handleKeyUp = (e) => {
    if (e.key === "Backspace") {
      adjustTextareaHeight();
    }
  };

  const getPlaceholderText = () => {
    switch (selectedOption) {
      case "option1":
        return "How can I help you stay healthy?";
      case "option2":
        return "What are your symptoms?";
      case "option3":
        return "Enter your health report";
      case "option4":
        return "Ask me about healthy eating";
      case "option6":
        return "How can I help you be mindful?";
      case "option7":
        return "How can I help you sleep better?";
      case "option8":
        return "How can I help you stay fit?";
      case "option9":
        return "Investors, ask me anything?";
      case "option11":
        return "How can I help reduce stress?";
      default:
        return "Enter your input";
    }
  };

  const updateBackgroundImage = (option) => {
    switch (option) {
      case "option1":
        setBackgroundVideo(video1);
        break;
      case "option2":
        setBackgroundImage(image2);
        break;
      case "option3":
        setBackgroundImage(image3);
        break;
      case "option4":
        setBackgroundImage(image4);
        break;
      case "option5":
        setBackgroundImage(image5);
        break;
      default:
        setBackgroundVideo(video1);
    }
  };

  // const handleStopButtonClick = () => {
  //   if (isAutoPlayInProgress) {
  //     // If auto-play is in progress, stop the currently playing auto-play audio
  //     if (currentAudio) {
  //       currentAudio.pause();
  //       currentAudio.currentTime = 0;
  //     }
  //     setIsAutoPlayInProgress(false);

  //     // Update the button to show the play icon
  //     setShowStopButton(false);
  //   } else if (audio) {
  //     // If not in auto-play, stop the currently playing user-initiated audio
  //     audio.pause();
  //     audio.currentTime = 0;

  //     // Update the button to show the play icon
  //     setShowStopButton(false);
  //   }

  //   // Reset other relevant states
  //   setIsSubmitting(false);
  //   setDisableSendButton(false);
  //   setDisableMicButton(false);
  //   setIsVoiceSynthesisActive(false);
  //   setIsAudioPlaying(false);
  //   setCurrentlyPlayingIndex(null);
  //   setCurrentAudio(null);
  //   setCurrentlyPlayingAudio(null);
  // };

  useEffect(() => {
    adjustTextareaHeight();
    window.addEventListener("click", handleWindowClick);

    const playResponseVoice = async () => {
      try {
        if (textResponse) {
          // Play the audio URL if it exists
          if (audioData[textResponse]) {
            /*const newIndex = userHistory.length; // Get the current index
            setCurrentlyPlayingIndex(newIndex); // Set currentlyPlayingIndex
            const audioElement = new Audio(audioData[textResponse]);
            setCurrentAudio(audioElement); // Set currentAudio
            setIsAudioPlaying(true); // Set isAudioPlaying to true
            setIsAutoPlayInProgress(true); // Set auto-play in progress
            audioElement.play(); // Play the audio
            audioElement.onended = () => {
              setIsAutoPlayInProgress(false); // Reset auto-play in progress when done
              setIsAudioPlaying(false);
            };*/
          } else {
            await triggerVoiceSynthesis(textResponse, true);
            setIsAudioPlaying(true);
            const currentlyPlayingAudio = userHistory.length;
            setCurrentlyPlayingIndex(currentlyPlayingAudio);
            setIsAutoPlayInProgress(false); // Reset auto-play in progress
            setIsAudioPlaying(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    playResponseVoice();

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [textResponse, audioData, userHistory]);

  const handleWindowClick = () => {
    setShowDropdown(false);
  };

  const removeClasses = () => {
    const elements = document.querySelectorAll(
      " .start-main-inner-container, .start_answer_policy, .start-central-navbar-backside-color, .start_mobile_1v3_side_nav, .start-sharebtn-wrapper, .start-bg-color, .start-chat-suggesst-title, .start-suggession-starter, .start-central-greeting-wrapper, .start-central-column-bbtn-wrapper, .start-input-container, .chat-help-btns-wrapper, .start-form, .start-logo-profile-img, .start-menu-logo-wrapper, .start-logo-profile, .start-menu-logo-kps-wrapper, .start-container,.start-q-and-a-container, .start-main-background-container,.start-nav-img, .start-nav-li, .start-mobile-navbar-items, .start-mobile-text, .start-main-three-colum-layout, .start-mobile-text-wrapper, .start-root, .start-output-area, .start-right-column,.start-central-column, .start-cental-video-wrapper,  .start-right-column, .start-left-column"
    );
    elements.forEach((element) => {
      element.classList.remove(
        "start-central-navbar-backside-color",
        "start-main-inner-container",
        "start-container",
        "start-central-column",
        "start-output-area",
        "start-right-column",
        // "start-video",
        "start-cental-video-wrapper",
        "start-right-column",
        "start-left-column",
        "start-mobile-text",
        "start-main-three-colum-layout",
        "start-root",
        "start-mobile-text-wrapper",
        "start-main-background-container",
        "start-nav-img",
        "start-nav-li",
        "start-mobile-navbar-items",
        "start-q-and-a-container",
        "start-input-container",
        "start-menu-logo-kps-wrapper",
        "start-logo-profile",
        "start-menu-logo-wrapper",
        "start-logo-profile-img",
        "start-form",
        "start_mobile_1v3_side_nav",
        "chat-help-btns-wrapper",
        "start-central-greeting-wrapper",
        "start-central-column-bbtn-wrapper",
        "start-suggession-starter",
        "start-chat-suggesst-title",
        "start-bg-color",
        "start-sharebtn-wrapper",
        "start_answer_policy"
      );
    });
  };

  const htmlContent = ``;
  const regex = /\*\*(.*?)\*\*/g;
  const styledHtmlContent = htmlContent.replace(
    regex,
    '<span class="bold-text">$1</span>'
  );

  // const addMargin = () => {
  // return /iPhone|iPod|iPad/i.test(navigator.userAgent)
  // ? { margin: "0 0 80px 0" }
  // : { margin: "0" };
  // }

  // const [isListening, setIsListening] = useState(false);

  // useEffect(() => {
  //   const recognition = new window.webkitSpeechRecognition(); // Create a SpeechRecognition instance

  //   // Event handler when speech recognition starts
  //   recognition.onstart = () => {
  //     setIsListening(true);
  //   };

  //   // Event handler when speech recognition ends
  //   recognition.onend = () => {
  //     setIsListening(false);
  //   };

  //   // Start speech recognition when the component mounts
  //   recognition.start();

  //   return () => {
  //     // Clean up the recognition instance when the component unmounts
  //     recognition.stop();
  //   };
  // }, []);

  //conversation starter
  const changeSelectedOption = async (option = "option1") => {
    try {
      // Send the selected option to the backend
      const res = await axiosInstance.post(CONVERSATION_STARTER, {
        selectedOption: option,
      });
      console.log("Option sent to backend:", option);
      //setIsSubmitting(true);
      setResponseTexts(res.data.responses);
      console.log(res.data.responses);
    } catch (error) {
      console.error("Error sending option to backend:", error);
    }
  };

  const handlechtst1ButtonClick = async () => {
    try {
      await changeSelectedOption();
      // Send the button text to the response endpoint
      const response = await axios.post(CONV_RESPONSE, {
        buttonText: responseTexts,
      });

      // Get the response back from the backend
      const { conversation: backendResponse } = response.data;

      console.log("Backend Response:", backendResponse);
    } catch (error) {
      console.error("Error sending button text to backend:", error);
    }
  };

  //option handling

  const changeSelectedOptionToOption2 = () => {
    mixpanel.track('Symptom Checker', { 'Button Type': 'sympotomchecker' })
    setSelectedOption("option2");
    changeSelectedOption("optioin2");
    setBackgroundColor("#eef0f4");
    setBackgroundreturnColor("#dbf4fa");
    // setBackgroundreturnColor("#C1D6F0");
    setBackgroundText("Symptoms Checker");
    setBackgroundImg(symptomChecker);
    setBackgroundMp(symptomCheckerMp4);
    setDeskNav(true);
    setReturnBar("option2");
    setCenterHome(false);
    setImgSuggession(symptomChecker);
    setSuggessionTitle("Let's understand your symptoms together and discuss tailored guidelines.");
    setBoxSuggession("#eeeeee");
  };

  const changeSelectedOptionToOption3 = () => {
    mixpanel.track('Report Explainer', { 'Button Type': 'reportexplainer' })
    setSelectedOption("option3");
    changeSelectedOption("optioin3");
    // setBackgroundColor("#e7fafc");
    setBackgroundColor("#eef0f4");
    setCenterHome(false);
    setBackgroundreturnColor("#b3edf2");
    setBackgroundText("Report Explainer");
    setBackgroundImg(reportAnalyzer);
    setBackgroundMp(reportAnalyzerMp4);
    setReturnBar("option3");
    setImgSuggession(reportAnalyzer);
    setDeskNav(true);
    setSuggessionTitle("I'll analyze your health reports in detail, providing insights and practical suggestions.")
    setBoxSuggession("#eeeeee");
  };

  const changeSelectedOptionToOption4 = () => {
    mixpanel.track('Healthy Foodie', { 'Button Type': 'healthyfoodie' })
    setSelectedOption("option4");
    changeSelectedOption("optioin4");
    setBackgroundColor("#eef0f4");
    // setBackgroundColor("#fef0f2");
    setBackgroundreturnColor("#efacae");
    setBackgroundText("Healthy Foodie");
    setBackgroundImg(calorieCounter);
    setBackgroundMp(calorieCounterMp4);
    setImgSuggession(calorieCounter);
    setSuggessionTitle("Count calories effortlessly with my help, maintaining a healthy diet to reach your fitness goals.");
    setReturnBar("option4");
    setBoxSuggession("#eeeeee");
    setDeskNav(true);
    setCenterHome(false);
  };

  const changeSelectedOptionToOption6 = () => {
    mixpanel.track('Mind Coach', { 'Button Type': 'mindcoach' })
    setSelectedOption("option6");
    setBackgroundColor("#eef0f4");
    // setBackgroundColor("#f8fff5");
    setBackgroundreturnColor("#cce7b1");
    setBackgroundText("Mind Coach");
    setBackgroundImg(wellnessCouch);
    setBackgroundMp(wellnessCouchMp4);
    setImgSuggession(wellnessCouch);
    setSuggessionTitle("Think of me as a caring friend who offers practical advice for overall well-being.");
    setReturnBar("option6");
    setBoxSuggession("#eeeeee");
    setDeskNav(true);
    setCenterHome(false);
  };

  const changeSelectedOptionToOption7 = () => {
    mixpanel.track('Sleep Helper', { 'Button Type': 'sleephelper' })
    setSelectedOption("option7");
    changeSelectedOption("optioin7");
    setBackgroundColor("#eef0f4");
    // setBackgroundColor("#fffbf0");
    setBackgroundreturnColor("#f9e29c");
    setCenterHome(false);
    setBackgroundText("Sleep Helper");
    setBackgroundImg(sleepHelper);
    setBackgroundMp(sleepHelperMp4);
    setImgSuggession(sleepHelper);
    setSuggessionTitle("Improve sleep quality with personalized tips from me, addressing sleep issues effectively.");
    setReturnBar("option7");
    setDeskNav(true);
    setBoxSuggession("#eeeeee");
  };

  const changeSelectedOptionToOption8 = () => {
    mixpanel.track('Workout Planner', { 'Button Type': 'workoutplanner' })
    setSelectedOption("option8");
    changeSelectedOption("optioin8");
    setBackgroundColor("#eef0f4");
    // setBackgroundColor("#f7efff");
    setBackgroundreturnColor("#c5b2fa");
    setCenterHome(false);
    setBackgroundText("Workout Planner");
    setBackgroundImg(workoutPlanner);
    setBackgroundMp(workoutPlannerMp4);
    setImgSuggession(workoutPlanner);
    setSuggessionTitle("Plan effective exercise routines with my expertise in fitness and health.");
    setReturnBar("option8");
    setDeskNav(true);
    setBoxSuggession("#eeeeee");
  };

  const changeSelectedOptionToOption9 = () => {
    mixpanel.track('Women’s Health', { 'Button Type': 'Women’shealth' })
    setSelectedOption("option9");
    changeSelectedOption("optioin9");
    setBackgroundColor("#eef0f4");
    // setBackgroundColor("#fff0fc");
    setBackgroundreturnColor("#ecacd6");
    setCenterHome(false);
    setBackgroundText("Women’s Health");
    setBackgroundImg(womenHealth);
    setBackgroundMp(investorRelationsMp4);
    setImgSuggession(womenHealth);
    setSuggessionTitle("Knightingale’s AI health guide tailored specifically for women's wellness.");
    setReturnBar("option9");
    setDeskNav(true);
    setBoxSuggession("#eeeeee");
  };

  const changeSelectedOptionToOption11 = () => {
    mixpanel.track('Stress Regulator', { 'Button Type': 'stressregulator' })
    setSelectedOption("option11");
    changeSelectedOption("optioin11");
    setBackgroundColor("#eef0f4");
    // setBackgroundColor("#fff2eb");
    setBackgroundreturnColor("#f9c399");
    setCenterHome(false);
    setBackgroundText("Stress Regulator");
    setBackgroundImg(careReminder);
    setBackgroundMp(careReminderMp4);
    setImgSuggession(careReminder);
    setSuggessionTitle("Empowering your well-being, one gentle reminder at a time.");
    setReturnBar("option11");
    setDeskNav(true);
    setBoxSuggession("#eeeeee");
  };

  const inputFileRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file.name);
      // Create a FormData object to send the image

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("image", file);

      // Send the image to the server using an HTTP POST request
      axios
        .post(VISION, formData)
        .then((response) => {
          // Handle the server's response here, which may contain the local file path where the image is saved.
          console.log("Image uploaded:", response.data.filePath);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    }
  };

  const handleImageUpload = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // console.log(imgSuggession)
  return (
    <div
      className="main-background-container background-video start-main-background-container"
    // style={{ backgroundImage: `url(${backgroundVideo})` }}
    >
      <div className="main-inner-container container start-main-inner-container">
        <form
          className="kps-chat-form form start-form"
          onSubmit={handleSubmit}
        // style={marginStyle}
        //  style={/iPhone|iPod|iPad/i.test(navigator.userAgent)? { margin: "0 0 80px 0" } : { margin: "0" }}
        >
          <select
            className="select-dropdown"
            value={selectedOption}
            onChange={handleOptionChange}
            disabled={!showOptions}
            required
          >
            <option value="option5">Select a goal</option>
            <option value="option1">Knightingale</option>
            <option value="option2">Symptom Guide</option>
            <option value="option3">Report Analyzer</option>
            <option value="option4">Calorie Counter</option>
            <option value="option6">Wellness Coach</option>
            <option value="option7">Sleep Helper</option>
            <option value="option8">Workout Planner</option>
            <option value="option9">Women’s Health</option>
            <option value="option10">Guru Lexi</option>
            <option value="option11">Care Reminder</option>
          </select>

          {isSelectSuggest ? (
            <>
              <div className="chat-suggesst-title  "></div>
            </>
          ) : null}

          <Chat
            userHistory={userHistory}
            currentlyPlayingIndex={currentlyPlayingIndex}
            isVoiceSynthesisInProgress={isVoiceSynthesisInProgress}
            handleStopButtonClick={handleStopButtonClick}
            playAudio={playAudio}
            showLoadingIndicator={showLoadingIndicator}
            handlechtst1ButtonClick={handlechtst1ButtonClick}
            setInput={setInput}
            responseTexts={responseTexts}
            imgSuggession={imgSuggession}
            suggessionTitle={suggessionTitle}
            selectedOption={selectedOption}
            boxSuggession={boxSuggession}
          />

          <div className="mobile-voice-container">
            <button
              type="button"
              className=" ripple-btn"
              onClick={handleMicButtonClick}
              disabled={isSubmitting}
            >
              <img
                className="chat-logo-image "
                src={voiceLogo}
                alt="Microphone"
              />
            </button>
            <span className="mobile-divider"></span>
          </div>
          {/* <div className="sharebtn-wrapper start-sharebtn-wrapper">
            <div className="sharebtn-inner">
              <div className="sharebtn">
                <button className="sharebtn-img" onClick={() => setShowShareWindow(true)}>
                <div class="tooltip">
                <img className="share" src={share} alt="share" />
                    <span class="tooltiptext">Share</span>
                  </div>
                  
                  
                  </button>
                {showShareWindow && (
                  <ShareWindow
                    generatedLink={uniqueLink}
                    onClose={() => setShowShareWindow(false)}
                  />
                )}
              </div>
            </div>
          </div> */}

          <div className="input-container start-input-container">
            <ChatInput
              setReturnBar={setReturnBar}
              textareaRef={textareaRef}
              input={input}
              setInput={setInput}
              getLetterCount={getLetterCount}
              handleInputChange={handleInputChange}
              handleKeyDown={handleKeyDown}
              handleKeyUp={handleKeyUp}
              isSubmitting={isSubmitting}
              disableMicButton={disableMicButton}
              getPlaceholderText={getPlaceholderText}
              suggestions={suggestions}
              showDropdown={showDropdown}
              handleSuggestionClick={handleSuggestionClick}
              disableSendButton={disableSendButton}
              handleMicButtonClick={handleMicButtonClick}
              isVoiceRecognitionActive={isVoiceRecognitionActive}
              dropdownRef={dropdownRef}
              getStyles={getStyles}
              isRecording={isRecording}
              handleImageChange={handleImageChange}
              inputFileRef={inputFileRef}
              handleImageUpload={handleImageUpload}
              selectedImage={selectedImage}
              imagePreview={imagePreview}
              boxSuggession={boxSuggession}
              selectedOption={selectedOption}
              setCenterHome={setCenterHome}
            />
            <div  className=" ">
           
           {chatStart  && <div className="answer_policy " >
              <div>
              Knightingale offers wellness insights, NOT professional medical advice.
              </div>
            </div>}
            </div>
          </div>

          <div className="chat-help-btns-wrapper chat-help-btns-wrapper-none">
            <div className="chat-help-btns-container">
              {/* <div className="chat-help-btns-stage stage-1">
                <div className="stage1-btn-wrapper">
                  <button
                    type="button"
                    onClick={changeSelectedOptionToOption2}
                    className="Symptom Guide button chat-help-btn1"
                  >
                    <img
                      src={myprofile}
                      className=" chat-help-btn-img"
                      alt="my profile"
                    />
                  </button>
                  <span>My Profile</span>
                </div>

                <div className="stage1-btn-wrapper">
                  <button
                    type="button"
                    onClick={changeSelectedOptionToOption3}
                    className="resport analyzer button chat-help-btn1"
                  >
                    <img
                      src={myprogress}
                      className=" chat-help-btn-img"
                      alt="my progress"
                    />
                  </button>
                  <span>My Progress</span>
                </div>

                <div className="stage1-btn-wrapper">
                  <button
                    type="button"
                    onClick={changeSelectedOptionToOption4}
                    className="fitness planer button chat-help-btn1"
                  >
                    <img
                      src={myplans}
                      className=" chat-help-btn-img"
                      alt=" Fitness Planera"
                    />
                  </button>
                  <span> My Plans</span>
                </div>
              </div> */}

              {/*image upload*/}

              <div className="stage-2-3-wrapper">
                <div className="chat-help-btns-stage stage-2">
                  <button
                    style={{
                      backgroundColor:
                        selectedOption === "option2" ? "#E9F3FF" : "",
                      borderColor:
                        selectedOption === "option2" ? "#00000010" : "",
                    }}
                    type="button"
                    onClick={changeSelectedOptionToOption2}
                    className={`calorie counter button ${selectedOption !== "option2" ? "button-hover" : ""
                      }`}
                  >

                    <img
                      className="chat-help-btn-img-st2"
                      src={symptomChecker}
                      alt="Symptom Checker"
                    />
                    <span>
                      Symptoms
                      <br /> Checker
                    </span>
                  </button>

                  <button
                    style={{
                      backgroundColor:
                        selectedOption === "option3" ? "#C2FAFE" : "",
                      borderColor:
                        selectedOption === "option3" ? "#00000010" : "",
                    }}
                    type="button"
                    onClick={changeSelectedOptionToOption3}
                    className={`relationship guru button ${selectedOption !== "option3" ? "button-hover" : ""
                      }`}
                  >
                    <img
                      className="chat-help-btn-img-st2"
                      src={reportAnalyzer}
                      alt=" Report Analyzer"
                    />
                    <span>
                      Report
                      <br /> Explainer
                    </span>
                  </button>

                  <button
                    style={{
                      backgroundColor:
                        selectedOption === "option4" ? "#FFBDBD" : "",
                      borderColor:
                        selectedOption === "option4" ? "#00000010" : "",
                    }}
                    type="button"
                    onClick={changeSelectedOptionToOption4}
                    className={`pregnancy manager button ${selectedOption !== "option4" ? "button-hover" : ""
                      }`}
                  >
                    <img
                      className="chat-help-btn-img-st2"
                      src={calorieCounter}
                      alt="Calorie Counter "
                    />
                    <span>
                      Healthy
                      <br /> Foodie
                    </span>
                  </button>

                  <button
                    style={{
                      backgroundColor:
                        selectedOption === "option6" ? "#E6FDD8" : "",
                      borderColor:
                        selectedOption === "option6" ? "#00000010" : "",
                    }}
                    type="button"
                    onClick={changeSelectedOptionToOption6}
                    className={`parenting coach button ${selectedOption !== "option6" ? "button-hover" : ""
                      }`}
                  >
                    <img
                      className="chat-help-btn-img-st2"
                      src={wellnessCouch}
                      alt=" Wellness Coach"
                    />
                    <span>
                      Mind
                      <br />
                      Coach
                    </span>
                  </button>
                </div>

                <div className="chat-help-btns-stage stage-3">
                  <button
                    style={{
                      backgroundColor:
                        selectedOption === "option7" ? "#FFF5CD" : "",
                      borderColor:
                        selectedOption === "option7" ? "#00000010" : "",
                    }}
                    type="button"
                    onClick={changeSelectedOptionToOption7}
                    className={`calorie counter button ${selectedOption !== "option7" ? "button-hover" : ""
                      }`}
                  >
                    <img
                      className="chat-help-btn-img-st2"
                      src={sleepHelper}
                      alt="Sleep Helper "
                    />
                    <span>
                      Sleep
                      <br /> Helper
                    </span>
                  </button>

                  <button
                    style={{
                      backgroundColor:
                        selectedOption === "option8" ? "#DFCFFF" : "",
                      borderColor:
                        selectedOption === "option8" ? "#00000010" : "",
                    }}
                    type="button"
                    onClick={changeSelectedOptionToOption8}
                    className={`relationship guru button ${selectedOption !== "option8" ? "button-hover" : ""
                      }`}
                  >
                    <img
                      className="chat-help-btn-img-st2"
                      src={workoutPlanner}
                      alt="Workout Planner"
                    />
                    <span>
                      Workout
                      <br /> Planner
                    </span>
                  </button>

                  <button
                    style={{
                      backgroundColor:
                        selectedOption === "option9" ? "#FFCAEB" : "",
                      borderColor:
                        selectedOption === "option9" ? "#00000010" : "",
                    }}
                    type="button"
                    onClick={changeSelectedOptionToOption9}
                    className={`pregnancy manager button ${selectedOption !== "option9" ? "button-hover" : ""
                      }`}
                  >
                    <img
                      className="chat-help-btn-img-st2"
                      src={womenHealth}
                      alt="Investor Relations"
                    />
                    <span>
                      Women’s
                      <br />
                      Health
                    </span>
                  </button>

                  <button
                    style={{
                      backgroundColor:
                        selectedOption === "option11" ? "#FFE0CA" : "",
                      borderColor:
                        selectedOption === "option11" ? "#00000010" : "",
                    }}
                    type="button"
                    onClick={changeSelectedOptionToOption11}
                    className={`parenting coach button ${selectedOption !== "option11" ? "button-hover" : ""
                      }`}
                  >
                    <img
                      className="chat-help-btn-img-st2"
                      src={careReminder}
                      alt="Care Reminder"
                    />
                    <span>
                      Stress
                      <br /> Regulator
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Form;
