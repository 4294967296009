import React, { useEffect, useRef, useState } from "react";
import Form from "../Form/Form";
import { Link, useParams } from "react-router-dom";
import navItem1 from "../../img/profile_iconm.png";
import navItem2 from "../../img/Icons/doc_icon_sm.png";
import Logo from "../../img/Logo.png";
import Menu from "../../img/burger_menu.png";
import navbarImage from "../../img/top_gradient.png";
import user from "../../img/user.png";
import menu from "../../img/menu.png";
import girl from "../../img/graphic.png";
import userlogo from "../../img/Icons/login.png";
import mainlogo from "../../img/logo/knightingale_logo.png";

import LoginPage from "../Login/Login";
import mainBgVideo from "../../videos/knightingale_character_loop.mp4";
import thumbkncharacter from "../../img/knightingale_character_thumbnail.jpg";
import "./MainLayout.css";
import "../Start/Start.css";
import Navbar from "../Navbar/Navbar";
import greetQuestion from "../../img/chat/greet-question.png";
import mornning from "../../img/chat/morning_icon.png";
import afternoon from "../../img/chat/afternoon_icon.png";
import evening from "../../img/chat/evening_icon.png";
import night from "../../img/chat/night_icon.png";
import home_icon from "../../img/versionthree/home_icon.png";
import share_icon from "../../img/versionthree/share_icon.png";
import chat_icon from "../../img/versionthree/chat_icon.png";
import burger_menu from "../../img/versionthree/burger_menu.svg";
import back_icon from "../../img/versionthree/back_icon.png";

import backIcon from "../../img/chat/backIcon.svg";
import symptomChecker from "../../img/chat/symptom-checker.png";
// import videoLoadingAnimation from "../../img/chat/logoloading.svg";
import knightingale_background_video from "../../videos/chat/knightingale_background_video.mp4";
import knightingale_background_video_mobile from "../../videos/chat/knightingale_background_video_mobile.mp4";
import knightingale_background_video_mobile_poster from "../../img/chat/knightingale_background_video_mobile_poster.jpg";

import reportAnalyzerMp4 from "../../videos/chat/report-analyzer.mp4";
import videoLoadingAnimation from "../../img/chat/videoLoadingSvg.tsx";
import VideoLoadingSvg from "../../img/chat/LogoLoading.js";
import knightingale_avatar_bar from "../../img/chat/knightingale_avatar_bar.gif";
import careReminderMp4 from "../../videos/chat/knightingale_character_loop.mp4";
import knightingale_character_loop_poster from "../../img/chat/knightingale_character_loop_poster.jpg";
import { useNavigate } from "react-router-dom";
import { color } from "framer-motion";
import { useShareWindow } from "../../context/ShareWindowContext.js";
import ShareWindow from "../Sharewindow/ShareWindow.js";
import axios from "axios";
import share from "../../img/chat/share.svg";
import descktop_poster from "../../img/chat/knightingale_background_thumbnail.jpg";
import knightingale_background_video_mobile_thumbnail from "../../img/chat/knightingale_background_video_mobile_thumbnail.jpg";

import Avator from "../Avatar/Avator.js";
// import knloopcharacter from '../../../public/knightingale_3d';


const axiosInstance = axios.create();
const axiosVoiceRecognitionInstance = axios.create();

const SET_NICKNAME = "https://backend.knightingale.ai/get-user-nickname";

function Test(props) {
  const buttonRef = useRef(null);
  const [backgroundColor, setBackgroundColor] = useState("#eef0f4");
  // const [backgroundreturnColor, setBackgroundreturnColor] = useState("#C1D6F0");
  // const [backgroundImg, setBackgroundImg] = useState(generalChat);
  // const [backgroundText, setBackgroundText] = useState("General Chat");
  const [backgroundMp, setBackgroundMp] = useState(knightingale_background_video);
  // const [backgroundMobileMp, setBackgroundMp] = useState(knightingale_character);
  const [centerHome, setCenterHome] = useState(true);
  const [mobileNav, setMobileNav] = useState(false);
  // const [deskNav, setDeskNav] = useState(false);
  const [isBack, setIsBack] = useState(false);

  const [returnBar, setReturnBar] = useState("option1");
  const [nickname, setNickname] = useState("");
  const { session: urlSession } = useParams();
  const {
    showShareWindow,
    setShowShareWindow,
    chatSession,
    setChatSession,
    generatedLink,
    setGeneratedLink,
    isButtonDisabled,
    backgroundText, setBackgroundText
    , backgroundImg, setBackgroundImg, backgroundreturnColor, setBackgroundreturnColor, deskNav, setDeskNav
  } = useShareWindow();

  console.log(isButtonDisabled);

  const uniqueLink = `https://guardian.knightingale.ai/share/${chatSession}`;

  const [conversationHistory, setConversationHistory] = useState([]);

  useEffect(() => {
    const videoElements = document.querySelectorAll(".start-video");

    const handleResize = () => {
      if (window.innerWidth < 767) {
        videoElements.forEach((video) => {
          video.src = knightingale_background_video_mobile;
          video.poster = knightingale_background_video_mobile_poster;
        });
      } else {
        videoElements.forEach((video) => {
          video.src = backgroundMp;
          video.poster = descktop_poster;
        });
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [knightingale_background_video_mobile, knightingale_background_video_mobile_poster, backgroundMp, descktop_poster]);


  useEffect(() => {
    // Fetch user nickname from the backend
    fetch(SET_NICKNAME)
      .then((response) => response.json())
      .then((data) => {
        setNickname(data.nickname);
      })
      .catch((error) => console.error("Error fetching user nickname:", error));
  }, []);

  useEffect(() => {
    buttonRef.current.click();
  }, []);

  const videoRef = useRef(null);

  const handlePlayClick = () => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((error) => {
        console.error("Autoplay error:", error);
      });
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const [greeting, setGreeting] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    const updateGreetingAndImage = () => {
      const currentHour = new Date().getHours();

      let newGreeting, newImage;

      if (currentHour >= 5 && currentHour < 12) {
        newGreeting = "Good Morning";
        newImage = mornning;
      } else if (currentHour >= 12 && currentHour < 17) {
        newGreeting = "Good Afternoon";
        newImage = afternoon;
      } else if (currentHour >= 17 && currentHour < 21) {
        newGreeting = "Good Evening";
        newImage = evening;
      } else {
        newGreeting = "Good Evening";
        newImage = night;
      }

      setGreeting(newGreeting);
      setImage(newImage);
    };

    updateGreetingAndImage(); // Initial update

    // Update every minute
    const intervalId = setInterval(updateGreetingAndImage, 60000);

    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run the effect only once

  const divStyle = {
    backgroundColor: backgroundColor,
  };

  const backgroundOpacity = .5;

  const returnStyle = {
    backgroundColor: `rgba(${parseInt(
      backgroundreturnColor.slice(1, 3),
      16
    )}, ${parseInt(backgroundreturnColor.slice(3, 5), 16)}, ${parseInt(
      backgroundreturnColor.slice(5, 7),
      16
    )}, ${backgroundOpacity})`,
    borderColor: backgroundreturnColor,
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Set loaded to true after the component has mounted
    setLoaded(true);
  }, []);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateScreenHeight = () => {
      const newHeight = Math.max(648, Math.min(1500, window.innerHeight));
      setScreenHeight(newHeight);
    };

    // Update screen height when the window is resized
    window.addEventListener("resize", updateScreenHeight);

    // Initial update
    updateScreenHeight();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  let backClicked = false;

  if (!centerHome) {
    window.addEventListener("popstate", function (event) {
      if (!backClicked) {
        backClicked = true;
      } else {
        window.addEventListener("hashchange", function () {
          console.log("Fragment identifier or URL changed");
          window.location.reload();
        });
      }
    });
  }

  useEffect(() => {
    if (centerHome === false) {
      const handleClick = () => {
        console.log("Auto click event triggered!");
      };

      const autoclickElement = document.getElementById("autoclick");

      if (autoclickElement) {
        autoclickElement.addEventListener("click", handleClick);
        autoclickElement.click();
        return () => {
          autoclickElement.removeEventListener("click", handleClick);
        };
      }
    }
  }, [centerHome]);

  const textareaRef = useRef(null);
  const outputContainerRef = useRef(null);
  const outputinnerContainerRef = useRef(null);

  const dropdownRef = useRef(null);
  const otContainerRef = useRef(null);

  const regexBold = /\*\*(.*?)\*\*/g;

  useEffect(() => {
    if (urlSession) {
      // If a session ID is present in the URL, use it
      setChatSession(urlSession);

      // Fetch conversation history for the current chat session
      axios
        .get(`https://backend.knightingale.ai/api/conversation-history/${urlSession}`)
        .then((response) => {
          // Update conversation history state
          setConversationHistory(response.data);
        })
        .catch((error) => {
          console.error("Error fetching conversation history:", error);
        });
    } else {
      // If no session ID in the URL, fetch a new session ID when the component mounts
      axios
        .get("https://backend.knightingale.ai/api/generate-session")
        .then((response) => {
          const newChatSession = response.data.chatSession;
          setChatSession(newChatSession);
          // Store the new session ID in localStorage
          localStorage.setItem("chatSession", newChatSession);
        })
        .catch((error) => {
          console.error("Error generating session:", error);
        });
    }
  }, [urlSession]);


  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [zstyle, setZstyle] = useState(false);

  const handleMouseEnterShare = () => {
    setZstyle(true)
    const timeout = setTimeout(() => {
      setShowTooltip(true);
    }, 2000); // 2 seconds
    setHoverTimeout(timeout);
  };

  const handleMouseLeaveShare = () => {
    clearTimeout(hoverTimeout);
    setShowTooltip(false);
    setZstyle(false)
  };

  const newChatClick = () => {
    // Store 'newchat' in localStorage
    localStorage.setItem('chatType', 'newchat');
    // alert("Value 'newchat' has been stored in localStorage.");
  };

  // useEffect(() => {
  //   console.log("first"+loaded)
  //   setDeskNav(false)
  // }, [!loaded]);
  return (
    <div
      className="main-three-colum-layout-wrapper start-bg-color"
      style={divStyle}
    >
      <div className="main-three-colum-layout-container">
        <div
          className="main-three-colum-layout start-main-three-colum-layout"
          style={divStyle}
        >
          <video
            ref={videoRef}
            className="new-mobile-background-video start-video"
            // onLoadedData={handleVideoLoad}
            playsInline
            muted
            loop
          />

          <button
            ref={buttonRef}
            onClick={() => handlePlayClick()}
            style={{ display: "none" }}
          ></button>
          <div className="left-column start-left-column">
            <div className="left-column-logo">
              <div className="nav_desktop_tbtn">
                <div className="toggle_menu_wrapper" onClick={() => { setDeskNav(!deskNav) }}>
                  <img className="toggle_menu_desk" src={burger_menu} alt="Image 1" />
                </div>
              </div>
              <a href="https://guardian.knightingale.ai/">
                <img
                  className="logo-kps menu-logo-kps "
                  src={mainlogo}
                  alt="Image 1"
                />

                {/* <HeartAnimation/> */}
              </a>
            </div>

            <div className={`left-column-vertical-buttons ${deskNav ? '' : 'deskNav'}`}>
              <div className="vertical-button">
                <a href="https://guardian.knightingale.ai/">
                  <img
                    className="vertical-button-img"
                    src={home_icon}
                    alt="Image 1"
                  />
                  <div className="side_nav_text">Home
                  </div>
                </a>
              </div>
              <div className="vertical-button">

                <div className="sharebtn-wrapper start-sharebtn-wrapper">
                  <div className="sharebtn-inner">
                    <div className="sharebtn"
                      onMouseEnter={handleMouseEnterShare}
                      onMouseLeave={handleMouseLeaveShare}
                    >

                      <button
                        className="sharebtn-img tooltip"
                        onClick={() => setShowShareWindow(true)}
                        // disabled={isButtonDisabled}

                      >
                        <img
                          className="vertical-button-img share"
                          src={share_icon}
                          alt="Image 1"
                        />
                        <div className="side_nav_text">Share</div>
                       
                      </button>
                      {showShareWindow && (
                        <ShareWindow
                          generatedLink={uniqueLink}
                          onClose={() => setShowShareWindow(false)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="vertical-button">
                <a href="https://guardian.knightingale.ai/">
                  <img
                    className="vertical-button-img"
                    src={chat_icon}
                    alt="Image 1"
                  />
                  <div className="side_nav_text">New Chat</div>
                </a>
              </div>
            </div>
            <div className="video-wrapper">
              <div className="new-background-video-container-chat videoclass">
                {/* <video
                  className=""
                  playsInline
                  autoPlay
                  loop
                  muted
                  // poster={knightingale_character_loop_poster}
                  src={careReminderMp4}
                /> */}
                {/* <Avator/> */}
                {/* <model-viewer
                  src="../../knightingale_3d.glb"
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  camera-controls
                  tone-mapping="commerce"
                  poster="poster.webp"
                  shadow-intensity="1"
                  autoplay
                  camera-orbit="-346.9deg 86.24deg 6.91m"
                  field-of-view="17.6deg"
                  auto-rotate
                  min-camera-orbit="56deg 80deg 7.699m"
                  max-camera-orbit="180deg 80deg auto"
                  min-field-of-view="15.4deg"
                >
                  <div class="progress-bar hide" slot="progress-bar">
                    <div class="update-bar"></div>
                  </div>
                </model-viewer> */}
              </div>
            </div>
          </div>
          {<a href="#" id="autoclick"></a>}

          <div
            className="center-column start-central-column "
          // style={{
          //   ...(centerHome
          //     ? {
          //       marginTop: `${screenHeight > 760
          //         ? 120
          //         : screenHeight > 600
          //           ? (screenHeight - 600) * 0.75
          //           : 0
          //         }px`,
          //     }
          //     : {}),
          // }}
          >

<div className="mobile_1v3_nav">
              <div className="mobile_1v3_nav_wrapper">
                <div className="mobile_1v3_nav_container">
                  <div className="mobile_1v3_nav_toggle">
                    <div className="toggle_menu_wrapper" onClick={() => { setMobileNav(!mobileNav) }}>
                      <img className="toggle_menu" src={burger_menu} alt="Image 1" />
                    </div>
                  </div>

                  <div className="mobile_1v3_nav_logo">
                    <a href="https://guardian.knightingale.ai/">
                      <img
                        className="logo-kps menu-logo-kps "
                        src={mainlogo}
                        alt="Image 1"
                      />
                    </a>
                  </div>
                  <div className="mobile_1v3_nav_profile">
                    <img className="user-kps " src={user} alt="Image 1" />
                    {/* <div className="desk_home_video_profile">
                      <div className="desk_home_video_container">
                        <div className="new-background-video-container-chat videoclass">
                          <div
                            style={{
                              position: `${audioVideoChange ? 'static' : 'absolute'}`,
                              width: '100%'

                            }}
                          >

                            <video
                              className={`new-background-video-container-chat-video `}
                              style={{
                                // opacity: `${audioVideoChange ? '0' : '1'}`, left: '-55%',
                                // zIndex: '-10',
                                // position: `${audioVideoChange ? 'relative' : 'static'} `
                              }}
                              playsInline
                              autoPlay
                              loop
                              muted
                              poster={thumbnail_user}
                              src={knightingale_background_video_mobile_user}
                            />
                          </div>
                          <div
                            style={{
                              position: `${audioVideoChange ? 'absolute' : 'static'}`,
                              opacity: `${audioVideoChange ? '1' : '0'}`,
                              transition: 'opacity 200ms ease',
                              width: '100%'
                            }}
                          >

                            <video
                              className="new-background-video-container-chat-video"
                              style={{
                                // left: '-65%',
                                // zIndex: '-10',
                                // position: `${audioVideoChange ? 'relative' : 'static'} `, opacity: `${audioVideoChange ? '1' : '0'} `
                              }
                              }
                              laysInline
                              autoPlay
                              loop
                              muted
                              poster={thumbnail_user}
                              src={knightingale_background_video_mobile_user}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile_1v3_side_nav_open start_mobile_1v3_side_nav ">
              <div className={`mobile_1v3_side_nav ${mobileNav ? "mobile_1v3_side_nav_open" : ""
                }`}>
                <div className={`mobile_1v3_side_nav_wrapper   ${mobileNav ? 'mob_bg_overlay' : ''}`}>
                  <div className="mobile_1v3_side_nav_container">
                    <div className="side_nav_menu_container">
                      <div className="side_nav_menu_item_wrapper">
                        <div className="side_nav_menu_item_container">
                          <div className=" side_nav_menu_item_back">
                            <a href="#" onClick={() => { setMobileNav(!mobileNav) }} className="">
                              <img
                                className="vertical-button-img"
                                src={back_icon}
                                alt="Image 1"
                              />
                            </a>
                          </div>
                          <div className="side_nav_menu_item_wrapper">

                            <div className="side_nav_menu_item">
                              <a href="https://guardian.knightingale.ai/">
                                <img
                                  className="vertical-button-img"
                                  src={home_icon}
                                  alt="Image 1"
                                />

                                <div className="side_nav_menu_item_text">
                                  Home
                                </div> </a>
                            </div>
                            <div className="side_nav_menu_item" onClick={() => setShowShareWindow(true)}>
                              <div className="sharebtn-wrapper start-sharebtn-wrapper">
                                <div className="sharebtn-inner">
                                  <div className="sharebtn"
                                    onMouseEnter={handleMouseEnterShare}
                                    onMouseLeave={handleMouseLeaveShare}
                                  >

                                    <button
                                      className="sharebtn-img tooltip"
                                      onClick={() => setShowShareWindow(true)}
                                    // disabled={isButtonDisabled}

                                    >
                                      <img
                                        className="vertical-button-img share"
                                        src={share_icon}
                                        alt="Image 1"
                                      />
                                      {showTooltip && <div class="tooltip">
                                        <span class="tooltiptext">Share</span>
                                      </div>}
                                    </button>
                                    {showShareWindow && (
                                      <ShareWindow
                                        generatedLink={uniqueLink}
                                        onClose={() => setShowShareWindow(true)}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="side_nav_menu_item_text">
                                Share
                              </div>
                            </div>

                            <div className="side_nav_menu_item" onClick={newChatClick}>
                              <a href="https://guardian.knightingale.ai/"  >
                                <img
                                  className="vertical-button-img"
                                  src={chat_icon}
                                  alt="Image 1"
                                />

                                <div className="side_nav_menu_item_text">
                                  New Chat
                                </div></a>
                            </div>
                          </div>
                        </div>
                        <div className="side_nav_menu_item_bottom">
                          <div className="side_nav_menu_item_bottom_text">
                            © 2024 Knightingale, Inc.
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="mobile_1v3_side_nav_profile">
                      <a href="#">
                        <img className="user-kps " src={user} alt="Image 1" />
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>


            <div className="desk_home_video_wrapper">
              <div className="desk_home_video_container">
                <div className="new-background-video-container-chat videoclass">
                  <video
                    className="new-background-video-container-chat-video"
                    playsInline
                    autoPlay
                    loop
                    muted
                    poster={knightingale_character_loop_poster}
                    src={careReminderMp4}
                  />

                </div>
              </div>
            </div>

            {/* {returnBar && (
              <div
                className="central-navbar-backside-color start-central-navbar-backside-color"
                style={returnStyle}
              ></div>
            )}
            {returnBar && (
              <div
                className="central-column-bbtn-wrapper start-central-column-bbtn-wrapper"
                style={zstyle ? { zIndex: '1000' } : { zIndex: '100' }}

              >
                <div className="central-column-bbtn-inner"
                  style={returnStyle}
                >
                  <div className="central-column-bbtn">
                    <div className="central-column-btn-middle-wrapper">
                      <div className="central-column-btn-middle-container">
                        {backgroundImg && <img
                          src={backgroundImg}
                          className="central-column-btn-middle-img"
                          alt=""
                        />}
                        <span>{backgroundText}</span>
                      </div>
                    </div>
                    <div className="knightingale_avatar_bar_wrapper">
                      <div className="knightingale_avatar_bar">
                        <img
                          src={knightingale_avatar_bar}
                          alt="knightingale avatar bar"
                        />
                      </div>
                    </div>
                    <div className="sharebtn-wrapper start-sharebtn-wrapper">
                      <div className="sharebtn-inner">
                        <div className="sharebtn"
                          onMouseEnter={handleMouseEnterShare}
                          onMouseLeave={handleMouseLeaveShare}
                        >

                          <button
                            className="sharebtn-img tooltip"
                            onClick={() => setShowShareWindow(true)}
                            disabled={isButtonDisabled}

                          >
                            <img className="share" src={share} alt="share" />

                            {showTooltip && <div class="tooltip">
                              <span class="tooltiptext">Share</span>
                            </div>}
                          </button> */}
            {/* {showShareWindow && (
                               <ShareWindow
                              generatedLink={uniqueLink}
                             onClose={() => setShowShareWindow(false)}
                         />
                         )} */}
            {/* </div>
                      </div>
                     </div>{" "}
                  </div> */}
            {/* <div className="central-column-btn-right"></div> */}
            {/* </div>
              </div>
            )} */}
            <div
              className={`central-greeting-wrapper start-central-greeting-wrapper app ${loaded ? "loaded" : ""
                }`}
            >

              <div className="central-greeting-container-wrapper" >
                <div className="central-greeting-container">
                  <div className="central-greeting-user">
                    <img src={image} className="central-greeting-img" alt="Sun" />
                    <span className="central-greeting-text">
                      {greeting}{" "}
                      <span className="central-greeting-user-name"></span>
                      <div className="central-greeting-question">
                        <span className="central-greeting-ques">
                          How are you feeling today?{" "}
                        </span>
                        <img src={greetQuestion} className="" alt="Greet Question" />
                      </div>
                    </span>
                  </div>

                  <div
                    className={`central-greeting-down-arrow ${loaded ? "central-greeting-down-arrow-animate" : ""
                      }`}
                  >
                    <span className=""></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="start-cental-video-wrapper start-central-video-display">
              <div className="video-wrapper videoclass" id="yourVideoDivId">
                <div className="new-background-video-container videoclass">
                  <video
                    className="start-video"
                    id="start_video"
                    playsInline
                    autoPlay
                    loop
                    muted
                  />
                  {/* <div class="thumbnail-overlay">
                    <VideoLoadingSvg />
                  </div> */}
                </div>
              </div>
            </div>
            <Form
              setBackgroundColor={setBackgroundColor}
              setBackgroundImg={setBackgroundImg}
              setBackgroundText={setBackgroundText}
              setBackgroundMp={setBackgroundMp}
              setBackgroundreturnColor={setBackgroundreturnColor}
              setReturnBar={setReturnBar}
              backgroundMp={backgroundMp}
              setCenterHome={setCenterHome}
            />
          </div>

          <div className="right-column ">
            <Navbar setCenterHome={setCenterHome} />
          </div>
        </div>
        {showShareWindow && (
          <ShareWindow
            generatedLink={uniqueLink}
            onClose={() => setShowShareWindow(false)}
          />
        )}
      </div>
    </div>
  );
}

export default Test;
